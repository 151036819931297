export interface IFilterColors {
	background: string
	nonInteractiveBackground: string
	filter: string
}

export const filterColors: IFilterColors[] = [
	{
		background: 'bg-warm-grey-100 hover:bg-warm-grey-200',
		nonInteractiveBackground: 'bg-warm-grey-100',
		filter: '#9191FF',
	},
	{
		background: 'bg-warning-100 hover:bg-warning-200',
		nonInteractiveBackground: 'bg-warning-100',
		filter: '#BC8714',
	},
]
